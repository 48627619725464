<template>
  <div class="grid-4n" ref="miniCategories">
    <div v-for="(category, index) in filteredCategories" :key="index" class="grid-item">
      <nuxt-link
        v-if="category && category.offerProductName && category.offerProduct"
        :to="getLink(category)"
      >
        <filter-tile>
          <template #image>
            <b-image
              ratio="1by1"
              :src="getImageBySize(category.imageGroup, 'big').src"
              :webp-fallback="getImageBySize(category.imageGroup, 'big').webpFallback"
              class="skeleton-image-placeholder"
              :alt="category.offerProductName || 'schuette product'"
              lazy
            >
              <template #placeholder>
                <b-skeleton />
              </template>
            </b-image>
          </template>
          <template #name>
            <h3>
              <template v-if="category.description">
                {{ category.description.name }}
              </template>
            </h3>
          </template>
          <template #buttons>
            <b-button type="is-primary" class="cta-primary" expanded rounded icon-right="arrow-right-bold">
              {{ $t('project.landingPage.browseAll') }}
            </b-button>
          </template>
        </filter-tile>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import FilterTile from '~/components/shared/FilterTile'

import equalHeight from '~/mixin/equalHeight'

export default {
  components: {
    FilterTile
  },
  mixins: [equalHeight],
  props: {
    categories: {
      type: Array,
      default () {
        return []
      }
    },
    home: {
      type: Boolean
    }
  },
  data () {
    return {
      titlesHeightStyleAdded: false
    }
  },
  computed: {
    filteredCategories () {
      return this.categories.filter(category => category.identifier !== 'relax')
    }
  },
  mounted () {
    this.resize()
    window.addEventListener('resize', this.resize)
  },
  destroyed () {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    resize () {
      if (window.innerWidth < 1024 && this.titlesHeightStyleAdded) {
        this.setEqualHeight(this.$refs.miniCategories, '.filter-name', false)
        this.titlesHeightStyleAdded = false
      } else if (window.innerWidth >= 1024 && !this.titlesHeightStyleAdded) {
        this.setEqualHeight(this.$refs.miniCategories, '.filter-name', true)
        this.titlesHeightStyleAdded = true
      }
    },
    getLink (category) {
      if (this.home) {
        // return this.getLinkProduct(category)
        return this.getLinkCategories(category.identifier)
      } else {
        return this.getLinkProduct(category)
      }
    },
    getLinkCategories (identifier) {
      switch (identifier) {
        case 'plisy':
          return this.localePath('categories-pleated-blinds')
        case 'roletki':
          return this.localePath('categories-roller-blinds')
        case 'zaslony':
          return this.localePath('categories-curtains')
        default:
          return null
      }
    },
    getLinkProduct (category) {
      if (category && category.offerProductName && category.offerProduct) {
        return this.localePath({
          name: 'product',
          params: {
            productName: this.toSlugTitle({ name: category.offerProductName }),
            productId: category.offerProduct
          }
        })
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.grid-item {
  transition: margin 200ms ease-in-out;
  h3 {
    font-size: 20px !important;
  }
}
</style>
