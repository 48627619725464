<template>
  <div class="category-faq">
    <div class="category-faq-icon">
      <question-mark-icon />
    </div>
    <div class="category-question-answer">
      <div class="category-question-container" @click="visible = !visible">
        <div class="category-question">
          <div class="category-question-icon">
            <question-mark-icon />
          </div>
          <h3>
            <slot name="question" />
          </h3>
        </div>
        <b-icon v-if="collapsable" class="collapsable-icon" :icon="visible ? 'chevron-up' : 'chevron-down'" :aria-label="$t('actions.readAnswer')" />
      </div>
      <div v-show="isVisible" class="category-answer">
        <slot name="answer" />
      </div>
    </div>
  </div>
</template>

<script>
import QuestionMarkIcon from '~/assets/images/homepage/question-mark-italic-square.svg?inline'

export default {
  name: 'CategoryFaq',
  components: { QuestionMarkIcon },
  props: {
    collapsable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      visible: true
    }
  },
  computed: {
    isVisible () {
      if (this.collapsable) {
        if (this.visible) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    }
  },
  created () {
    this.visible = !this.collapsable
  }
}
</script>

<style lang="scss" scoped>
h3 {
  font-size: 24px;
  font-weight: 600;
  @include mobile {
    font-size: 20px;
  }
}

.category-faq {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  @include mobile {
    flex-direction: column;
  }
  .category-faq-icon svg, .category-question-icon svg {
    width: 32px;
    height: 32px;
  }
  .category-faq-icon {
    display: block;
    @include mobile {
      display: none;
    }
  }
  .category-question-icon {
    display: none;
    @include mobile {
      display: flex;
    }
  }
  .category-question-answer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex-grow: 1;
    width: 100%;
    .category-question-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      &:hover {
        cursor: pointer;
        .collapsable-icon {
          color: #666666;
        }
      }
    }
    .category-question {
      display: flex;
      justify-content: space-between;
      gap: 16px;
    }
    .collapsable-icon {
      margin-top: 6px;
      color: #c4c4c4;
    }
    .category-answer {
      // display: none;
      a {
        color: #498CF8;
      }
      p + p, p + .check-list-container, p + ol {
        margin: 16px 0;
      }
      b {
        font-weight: 600;
      }
      .blockquote {
        padding: 16px;
        border-radius: 8px;
        background-color: #f5f5f5;
      }
    }
  }
}
</style>
