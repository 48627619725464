<template>
  <div class="filter-tile">
    <div v-if="$slots.image" class="filter-image">
      <slot name="image" />
    </div>
    <div v-if="$slots.colors" class="filter-colors">
      <slot name="colors" />
    </div>
    <div v-if="$slots.name" class="filter-name">
      <slot name="name" />
    </div>
    <div v-if="$slots.buttons" class="filter-buttons">
      <slot name="buttons" />
    </div>
    <div v-if="$slots.features" class="filter-features">
      <slot name="features" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterTile'
}
</script>

<style lang="scss" scoped>
$borderGray: #D9D9D9;
$active-color-border-color: #808080;

.filter-tile {
  // display: flex;
  // flex-direction: column;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  .filter-image {
    position: relative;
    width: 100%;
    img, video {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .filter-colors-container {
    display: flex;
    justify-content: center;
    margin: 16px 0;
    .filter-colors {
      display: grid;
      grid-template-columns: repeat(7, 32px);
      // gap: 8px;
      .filter-color-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        &.active {
          border: 2px solid $active-color-border-color;
          border-radius: 50%;
          .filter-color {
            box-shadow: none;
          }
        }
        .filter-color {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          box-shadow: 0 2px 2px rgba(#000000, 0.2);
          cursor: pointer;
        }
      }
    }
  }
  .filter-name {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0;
  }
  .filter-buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 12px;
  }
  .filter-features {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 24px 16px;
    .filter-feature {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding-top: 16px;
      border-top: 1px solid $borderGray;
      .feature-icons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        svg {
          display: inline-block;
          width: 32px;
          height: 32px;
        }
      }
      .feature-description {
        font-size: 14px;
        text-align: center;
      }
    }
  }
}
</style>
